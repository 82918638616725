export function getTopics() {

    let publishTopic = localStorage.getItem("topic");
    let statusTopic = localStorage.getItem("statusTopic");
    console.log(publishTopic)
    console.log(statusTopic)
    if (publishTopic !== null && statusTopic !== null && publishTopic !== "" && statusTopic !== "") {
        console.log("returning topics")
        return { publishTopic: JSON.parse(publishTopic), statusTopic: JSON.parse(statusTopic) }
    } else {
        alert('MQTT topics are not set yet, Please go to settings page to update topics');
        return {}
    }
}

export async function connectToBroker() {
    // return async () => {
    const AWS = require("aws-sdk");
    const AWSIoTData = require("aws-iot-device-sdk");
    console.log("*************************11111111")
    let awsConfig = {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        mqttEndpoint: `${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com`,
        region: process.env.REACT_APP_REGION,
        clientId: process.env.clientId,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
    };

    const mqttClient = AWSIoTData.device({
        region: awsConfig.region,
        host: awsConfig.mqttEndpoint,
        protocol: "wss",
        maximumReconnectTimeMs: 8000,
        debug: false,
        accessKeyId: "",
        secretKey: "",
        sessionToken: "",
    });
    console.log("*************************22222222222")

    AWS.config.region = awsConfig.region;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: awsConfig.identityPoolId,
    });
    console.log("*************************3333333333")

    AWS.config.credentials.get((err) => {
        if (err) {
            console.log("*************************errrrrrrrrr")
            console.log(AWS.config.credentials);
            throw err;
        } else {
            console.log("*************************444444444444")

            mqttClient.updateWebSocketCredentials(
                AWS.config.credentials.accessKeyId,
                AWS.config.credentials.secretAccessKey,
                AWS.config.credentials.sessionToken
            );
        }
    });
    console.log("*************************55555555555555")

    mqttClient.on("reconnect", (err) => {
        console.log("mqttClient reconnect:", err);
    });

    mqttClient.on("connect", function () {
        console.log("connected");
    });
    console.log("*************************66666666")
    mqttClient.on('close', function () {
        console.log(' MQTT cl');
    });

    mqttClient.on('error', function (topic, payload) {
        console.log('Error IN MQTT', topic, payload);
    });
    return mqttClient
}


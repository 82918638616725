import './App.css';
import React from 'react';

function LandingPage() {
    return (
        <div className="main_wrapper">
            <header><a href="#"><img src="logo.png" alt="" /></a></header>
            <div className="margin40"> please go to <a href="/home">home</a> page</div>
            <footer><img src="footer-logo.png" alt="" /></footer>
        </div>
    );
}

export default LandingPage;

// import con
export async function getPricingCatalog(locationId) {
    console.log("locationId", locationId)
    let url = "http://18.159.36.9/fetchAppConfig";
    // let url = "https://muqfdnbqnb.execute-api.ap-south-1.amazonaws.com/dev/getorderdetails";
    let fetchCatalogResponse = await fetch(url, {
        // mode: 'no-cors',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            locationId,
        }),
    });
    let res = await fetchCatalogResponse.json();
    console.log(res);
    return res;
}
export async function getTransactionDetails(transactionId) {
    console.log("transactionId", transactionId)
    let url = "https://muqfdnbqnb.execute-api.ap-south-1.amazonaws.com/dev/gettransactiondetails";
    // let url = "https://muqfdnbqnb.execute-api.ap-south-1.amazonaws.com/dev/getorderdetails";
    let fetchTransactionResponse = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            transactionId: transactionId,
        }),
    });
    console.log("fetchTransactionResponse0", fetchTransactionResponse);

    let res = await fetchTransactionResponse.json();
    console.log(res);
    return res.amount;
}
export async function getOrderDetails(paymentOrderId) {
    console.log("paymentOrderId", paymentOrderId)
    // let url = "https://muqfdnbqnb.execute-api.ap-south-1.amazonaws.com/dev/gettransactiondetails";
    // let url = "https://muqfdnbqnb.execute-api.ap-south-1.amazonaws.com/dev/getorderdetails";
    let url = "https://muqfdnbqnb.execute-api.ap-south-1.amazonaws.com/dev/getorderdetails?paymentOrderId=" + paymentOrderId
    let fetchTransactionResponse = await fetch(url, {
        method: "GET"
    });
    console.log("fetchTransactionResponse0", fetchTransactionResponse);
    let res = await fetchTransactionResponse.json();
    console.log(res);
    return res
}
export async function createOrder(amount, dispenserNo, itemCount, deviceName, deviceId, deviceMqttTopic, deviceStatusMqttTopic, dispenserName, relayNumber, itemId) {
    console.log("crete orderrrrrrrr", amount, dispenserNo, itemCount, deviceName, deviceId, deviceMqttTopic, deviceStatusMqttTopic, dispenserName, relayNumber, itemId)
    let createOrderResponse = await fetch(
        "https://muqfdnbqnb.execute-api.ap-south-1.amazonaws.com/dev/createorder",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "mid": "RCTST1000094603",
                "orderId": 1,
                "termId": 3,
                "amount": amount / 100,
                "redirectUrl": "http://wevend.link/transactionResponse",
                "metaData": { dispenserNo, itemCount, amount, deviceName, deviceId, deviceMqttTopic, deviceStatusMqttTopic, dispenserName, relayNumber, itemId }
            }),
        }
    );
    return await createOrderResponse.json();


}
import './App.css';
import Card from './Card'
import { useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';

function App() {
  const [locationId, setLocationId] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    console.log(searchParams); // ▶ URLSearchParams {}
    let locationId = searchParams.get('locationId')
    console.log("locationId", locationId);
    setLocationId(locationId)
  }, []);


  return (
    <div className="main_wrapper">
      <header><a href="#"><img src="logo1.png" alt="" /></a></header>
      {locationId ? <Card locationId={locationId} /> : <div className="margin40">No Location Found Please Contact Admin</div>}
      <footer><img src="footer-logo.png" alt="" /></footer>
    </div>
  );
}

export default App;

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import React from 'react';
import { createOrder, getPricingCatalog } from './util/api';
import PuffLoader from "react-spinners/PuffLoader";
const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};
class Card extends React.Component {
    state = {
        activeIndex: 0,
        amount: 150,
        itemCount: 1,
        dispenser: 1,
        catalog: {},
        loading: true
    };
    componentDidMount = async () => {
        console.log("-----------------------")
        console.log(this.props.locationId);
        console.log("-----------------------")
        let catalog = await getPricingCatalog(this.props.locationId)
        this.setState({ catalog: catalog, loading: false })
        console.log(catalog);
    }
    updateDispenserNumber = (direction) => {
        let dispenserNo = this.state.dispenser
        direction === "left" ? dispenserNo-- : dispenserNo++
        console.log("updateDispenserNumber")
        this.setState({ dispenser: dispenserNo });
    }
    updateRadioGroup = (index, amt, count, deviceName, deviceId, deviceMqttTopic, deviceStatusMqttTopic, dispenserName, relayNumber, itemId) => {
        console.log("setting stateeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",{ index, amt, count, deviceName, deviceId, deviceMqttTopic, deviceStatusMqttTopic, dispenserName, relayNumber, itemId})
        this.setState({ activeIndex: index, amount: amt, itemCount: count, deviceName, deviceId, deviceMqttTopic, deviceStatusMqttTopic, dispenserName, relayNumber, itemId });
    }
    onPayClick = async () => {
        console.log("active index", this.state.activeIndex)
        console.log("amount amount", this.state.amount)
        let { deviceName, deviceId, deviceMqttTopic, deviceStatusMqttTopic, dispenserName, relayNumber, itemId } = this.state
        console.log(this.state.amount, this.state.dispenser, this.state.itemCount, deviceName, deviceId, deviceMqttTopic, deviceStatusMqttTopic, dispenserName, relayNumber, itemId)
        console.log("catelog", this.state.catalog)
        let createOrderResponse = await createOrder(this.state.amount, this.state.dispenser, this.state.itemCount, deviceName, deviceId, deviceMqttTopic, deviceStatusMqttTopic, dispenserName, relayNumber, itemId)
        console.log(createOrderResponse);

        // if (createOrderResponse.returnCode === 200) {
        window.location.href = `https://wevend.world/${createOrderResponse.paymentOrderId}/gumball`
        // www.test.com/?a=${queryA}&b=${queryB}`
    }
    getCardItems = () => {
        let Items = []
        let { activeIndex } = this.state;
        let { catalog } = this.state
        console.log(activeIndex, "111111111catalog", catalog)
        if (Object.keys(catalog).length != 0) {
            let locationId = catalog.locationId
            console.log("2222222222locationId", locationId)
            catalog.devices.map((device, index) => {
                let { deviceName, deviceId, deviceMqttTopic, deviceStatusMqttTopic } = device
                console.log("333333333The current device is: " + device);
                console.log(deviceName, deviceId, deviceMqttTopic, deviceStatusMqttTopic)
                device.dispensers.map((dispenser, index) => {
                    let { dispenserName, relayNumber, dispenserImageUrl, itemId } = dispenser
                    console.log("444444444The current dispenser is: " + dispenser);
                    console.log(dispenserName, relayNumber, dispenserImageUrl, itemId)
                    console.log("The current iteration is: " + index);
                    Items.push(
                        <div key={index} className="slider_card">
                            <h4>{itemId}</h4>
                            <div className="image_block"><img src={dispenserImageUrl} alt=""></img></div>
                            <div className="price_wrapper">
                                {
                                    dispenser.buttons.map((button, index) => {
                                        let { text, price, quantity } = button;
                                        console.log("55555555555 current dispenser is: " + dispenser.buttons);
                                        console.log("index is",text, price, quantity, index)
                                        return <div key={index} className={activeIndex === index ? 'block active' : 'block'} onClick={() => this.updateRadioGroup(index, price, quantity, deviceName, deviceId, deviceMqttTopic, deviceStatusMqttTopic, dispenserName, relayNumber, itemId)}>{text}</div>
                                    })
                                }
                                <div>{deviceName + "    " + dispenserName}</div>
                            </div>
                        </div>
                    )
                })
            })
        } else {
            return (<div className="margin40">No Price Catalog Found For This Location</div>)
        }
        return (
            <div className="inner">
                <Carousel showThumbs={false} showIndicators={false} showStatus={false}
                    renderArrowPrev={(clickHandler, hasPrev) => {
                        return (
                            <div className={`${hasPrev ? 'absolute' : 'hidden'} top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20 blueColor`}
                                onClick={() => {
                                    this.updateDispenserNumber("left")
                                    clickHandler()
                                }}>
                                <FaArrowCircleLeft className="w-9 h-9 text" />
                            </div>
                        );
                    }}
                    renderArrowNext={(clickHandler, hasNext) => {
                        return (
                            <div className={`${hasNext ? '' : 'hidden'} absolute top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20 blueColor`}
                                onClick={() => {
                                    this.updateDispenserNumber("right")
                                    clickHandler()
                                }}>
                                <FaArrowCircleRight className="w-9 h-9 text" />
                            </div>
                        );
                    }}>
                    {Items}
                </Carousel>
                <a className="paybutton" onClick={this.onPayClick}>Pay</a>
            </div>
        )
    }
    render() {
        console.log("this state", this.state)
        var renderedOutput = this.getCardItems()
        return (
            <div className="slider_wrapper">
                {this.state.loading ?
                    <PuffLoader
                        color={"red"}
                        loading={this.state.loading}
                        cssOverride={override}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                    : <div>{renderedOutput}</div>
                }
            </div>
        );
    }
}

export default Card;

import * as React from "react";
import { connectToBroker, getTopics } from "../util/helper";
// var fs = require("fs");
import { getOrderDetails } from "../util/api";
import logo from "../receiptImages/logo.png";
import FLogo from "../receiptImages/footer-logo.png";
import success from "../receiptImages/payment-successful.gif";

class ReceiptPage extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
        messages: [{ message: 'Please wait for device response' }]
    }
    componentDidMount = async () => {
        console.log(this.props)
        // let messageArray = this.state.messages
        // setTimeout(function () {
        //     if (messageArray.length < 2) {
        //         messageArray.push({ message: 'Device is unreachable, please contact admin' })
        //         this.setState({ messages: messageArray });
        //         alert("Device is unreachable, please contact admin")
        //     }
        // }.bind(this), 10000)
        // let topics = getTopics();
        // console.log("topics", topics)
        // let amount = await getTransactionDetails(window.location.pathname.split("/")[1])
        // let order = await getOrderDetails(window.location.pathname.split("/")[1])
        let order = await getOrderDetails(this.props.paymentOrderId)
        console.log("order", order)
        let orderDetails = order.orderDetails;
        let { amount, deviceId, deviceMqttTopic, deviceStatusMqttTopic, deviceName, dispenserName, dispenserNo, itemCount, itemId, relayNumber } = orderDetails.metaData
        console.log("order detailsssssssssssssssssssssss")
        console.log({ amount, deviceId, deviceMqttTopic, deviceStatusMqttTopic, deviceName, dispenserName, dispenserNo, itemCount, itemId, relayNumber })
        console.log("order detailsssssssssssssssssssssss")
        console.log("getorderdetails metadata items", orderDetails.metaData.itemCount, orderDetails.metaData.dispenserNo)
        // if (Object.keys(topics).length !== 0) {
        this.CreateMQTTClient(orderDetails.metaData.amount, orderDetails.metaData.itemCount, orderDetails.metaData.relayNumber, deviceMqttTopic, deviceStatusMqttTopic)
        // }
    }
    CreateMQTTClient = async (Amount, Count, Relay, deviceMqttTopic, deviceStatusMqttTopic) => {
        console.log("CreateMQTTClient")
        let client = await connectToBroker()
        console.log("client.mqttCient", client)
        if (client) {
            const message = { Amount, Count, Relay };
            console.log("message", message)
            client.publish(
                deviceMqttTopic,
                JSON.stringify(message),
            );
            client.subscribe(
                deviceStatusMqttTopic,
            );
            client.on('message', function (topic, payload) {
                let response = JSON.parse(payload.toString())
                console.log(response);
                let messageArray = this.state.messages
                messageArray.push(response)
                this.setState({ messages: messageArray });
            }.bind(this));
        }
    }
    render() {
        console.log(this.state)
        return (
            <div className="main_wrapper">
                <header><a><img src={logo} alt="" /></a></header>
                <div className='paymentFailImageHolder'>
                    <h3 style={{ textAlign: "center" }}>Payment Completed , Thank You!</h3>
                    <img src={success} alt="" />
                    {this.state.messages.map(function (item, i) {
                        console.log('test', item);
                        return <h5 key={i} style={{ textAlign: "center" }}>{JSON.stringify(item)}</h5>;
                    })}
                </div>
                <footer><img src={FLogo} alt="" /></footer>
            </div>
        )
    }
}
export default ReceiptPage;

import React, { useState } from 'react';
import './App.css';

function Settings() {
    const [topic, setTopic] = useState(() => {
        const saved = localStorage.getItem("topic");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });
    const [statusTopic, setStatusTopic] = useState(() => {
        const saved = localStorage.getItem("statusTopic");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });

    const handleChange = (e) => {
        setTopic(e.target.value);
    }

    const handleStatusTopicChange = (e) => {
        setStatusTopic(e.target.value);
    }

    // useEffect(() => {
    //     console.log("localStorage topic hook")
    //     localStorage.setItem("topic", JSON.stringify(topic));
    // }, [topic]);
    // useEffect(() => {
    //     console.log("localStorage statusTopic hook")
    //     localStorage.setItem("statusTopic", JSON.stringify(statusTopic));
    // }, [statusTopic]);
    const handleSubmit = (e) => {

        localStorage.setItem("topic", JSON.stringify(topic));
        localStorage.setItem("statusTopic", JSON.stringify(statusTopic));

        alert('Settings are saved with Device Topic :' + topic +
            ' , Status topic :' + statusTopic);
        e.preventDefault();
    }
    return (
        <div className="main_wrapper">
            <header><a href="#"><img src="logo.png" alt="" /></a></header>
            <form onSubmit={(e) => { handleSubmit(e) }}>

                <h3> Add topics here </h3>
                <label >Device Topic:</label><br />
                <input type="text" value={topic} required onChange={(e) => { handleChange(e) }} /><br />
                <label >Status topic:</label><br />
                <input type="text" value={statusTopic} required onChange={(e) => { handleStatusTopicChange(e) }} /><br />
                <input type="submit" value="Submit" />
            </form>
            <footer><img src="footer-logo.png" alt="" /></footer>
        </div>
    );
}

export default Settings;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './App';
import PaymentFailure from './pages/PaymentFailure'
import ReceiptPage from './pages/ReceiptPage'
import TransactionResponse from './pages/TransactionResponse'
import LandingPage from './pages/LandingPage'
import Settings from './pages/Settings'
import {BrowserRouter, Routes, Route } from "react-router-dom";
export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/paymentfailed/:code" element={<PaymentFailure/>} />
        {/* <Route path="/:transactionId"  element={(props) => <ReceiptPage {...props}/>} /> */}
        <Route path="/:transactionId" element={<ReceiptPage/>} />
        <Route path="/transactionResponse" element={<TransactionResponse/>} />
        {/* <Route path="/settings" element={<Settings />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


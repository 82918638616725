import * as React from "react";
import { useSearchParams } from 'react-router-dom';
import PaymentFailedPage from './PaymentFailure';
import ReceiptPage from './ReceiptPage';


function TransactionResponse() {
    const [searchParams] = useSearchParams();

    console.log(searchParams)
    console.log(searchParams.get('paymentOrderId'))
  
    return (
        <>
            {searchParams.get('success')==1 ? (
                // <PostPayment />
                <ReceiptPage paymentOrderId={searchParams.get('paymentOrderId')}/>
            ) : (
                <PaymentFailedPage />
            )}      </>

    )
}
export default TransactionResponse;
